export const classes = {
  brute: {
    total: 29,
    starting: 13,
  },
  cragheart: {
    total: 30,
    starting: 14,
  },
  mindthief: {
    starting: 13,
    total: 29,
  },
  scoundrel: {
    total: 28,
    starting: 12,
  },
  spellweaver: {
    total: 27,
    starting: 11,
  },
  tinkerer: {
    total: 31,
    starting: 15,
  },
  sun: {
    locked: true,
    total: 30,
    starting: 14,
  },
  trapper: {
    locked: false,
    total: 28,
    starting: 12,
  },
  "angry-face": {
    locked: true,
    total: 31,
    starting: 15,
    order: [
      22, 4, 8, 30, 21, 17, 6, 13, 11, 27, 1, 28, 25, 29, 12, 7, 23, 5, 20, 26,
      10, 15, 31, 18, 2, 14, 3, 24, 9, 19, 16,
    ],
  },
  triangle: {
    locked: true,
    total: 29,
    starting: 13,
    order: [
      22, 21, 11, 23, 25, 26, 15, 16, 14, 12, 17, 2, 7, 1, 5, 3, 4, 20, 13, 18,
      29, 10, 24, 27, 19, 28, 6, 9, 8,
    ],
  },
  "triple-spears": {
    locked: true,
    total: 28,
    starting: 12,
    order: [
      21, 13, 27, 15, 12, 10, 4, 11, 6, 7, 26, 20, 17, 2, 5, 25, 28, 9, 19, 24,
      3, 16, 23, 18, 14, 8, 22, 1,
    ],
  },
  cthulhu: {
    locked: true,
    total: 30,
    starting: 14,
    order: [
      28, 13, 18, 22, 4, 26, 8, 12, 17, 11, 20, 9, 29, 6, 27, 19, 25, 10, 24,
      16, 1, 30, 23, 5, 2, 3, 21, 14, 15, 7,
    ],
  },
  "lightning-bolt": {
    locked: true,
    total: 29,
    starting: 13,
    order: [
      22, 18, 26, 10, 15, 2, 8, 9, 16, 4, 29, 17, 20, 21, 5, 25, 12, 14, 24, 23,
      13, 11, 28, 6, 7, 3, 1, 19, 27,
    ],
  },
  "music-note": {
    locked: true,
    total: 28,
    starting: 12,
    order: [
      16, 7, 21, 27, 2, 25, 24, 20, 11, 23, 28, 15, 22, 4, 9, 6, 8, 10, 12, 13,
      18, 17, 14, 1, 26, 5, 3, 19,
    ],
  },
  saw: {
    locked: true,
    total: 31,
    starting: 15,
  },
  "two-mini": {
    locked: true,
    total: 30,
    starting: 14,
  },
  circles: {
    locked: true,
    total: 29,
    starting: 12,
  },
  eclipse: {
    locked: true,
    total: 28,
    starting: 12,
  },
  diviner: {
    locked: false,
    total: 28,
    starting: 12,
  },
  demolitionist: {
    locked: false,
    total: 27,
    starting: 12,
  },
  "red-guard": {
    locked: false,
    total: 28,
    starting: 13,
  },
  voidwarden: {
    locked: false,
    total: 29,
    starting: 14,
  },
  hatchet: {
    locked: false,
    total: 28,
    starting: 13,
  },
};
